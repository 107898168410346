// core modules
import { CommonModule } from "@angular/common";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
// import ngx-translate and the http loader
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { NgChartsModule } from "ng2-charts";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { TableSortableHeader } from "src/app/directives/tablesortableheader.directives";
import { UsersComponent } from "src/app/pages/users/users.component";
import { ViewContractComponent } from "src/app/pages/view-contract/view-contract.component";
import { FilterPipe } from "../../common/customPipes/filter.pipe";
import { AddProductComponent } from "../../pages/add-product/add-product.component";
import { ApisComponent } from "../../pages/apis/apis.component";
import { ContractsComponent } from "../../pages/contracts/contracts.component";
import { DashboardComponent } from "../../pages/dashboard/dashboard.component";
import { ViewProductsComponent } from "../../pages/view-products/view-products.component";
import { SharedModule } from "../../shared.module";
import { ManufacturerComponent } from "./../../pages/manufacturer/manufacturer.component";
// project modules
import { QRCodeModule } from "angularx-qrcode";
import { PlanComponent } from "src/app/pages/plan/plan.component";
import { QrCodePopupComponent } from "src/app/pages/view-products/qr-code-popup/qr-code-popup.component";
import { ManagerLayoutRoutes } from "./manager-layout.routing";
@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(ManagerLayoutRoutes),
    NgChartsModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    SharedModule,
    // ngx-translate and the loader module
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    PdfViewerModule,
    AngularEditorModule,
    QRCodeModule,
  ],
  declarations: [
    DashboardComponent,
    ViewProductsComponent,
    AddProductComponent,
    TableSortableHeader,
    FilterPipe,
    UsersComponent,
    ManufacturerComponent,
    ApisComponent,
    ContractsComponent,
    ViewContractComponent,
    PlanComponent,
    QrCodePopupComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ManagerLayoutModule {}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
