// core modules
import { Component, EventEmitter, OnInit, Output, Input } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbDateStruct, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { DomSanitizer } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";

// product modules
import {
  FILE_TYPE,
  SOURCE,
  todayDate,
  VALIDATION_REGEX,
  LIBRARY_COUNT,
  EDITOR_CONFIG,
  PlanType,
} from "../../common/constants";
import {
  getS3Url,
  removeNullOrEmpty,
  convertMSToNgbDate,
  getFromStorage,
} from "../../common/utils";
import { Caracteristic } from "../../models/Caracteristic";
import { Country } from "../../models/country";
import { File } from "../../models/file";
import { Links } from "./../../models/links";
import { Language } from "../../models/language";
import { ProductService } from "../../services/product.service";
import {
  ERROR_MESSAGES,
  SUCCESS_MESSAGES,
  MAX_FILE_SIZE,
  PLATFORM,
} from "./../../common/constants";
import { Product } from "src/app/models/product";
import { ConfirmationModalComponent } from "src/app/components/modals/confirmation-modal/confirmation-modal.component";
import { AddCharacteristicsComponent } from "src/app/components/modals/add-characteristics/add-characteristics.component";
import { AddMediaComponent } from "src/app/components/modals/add-media/add-media.component";
import { AddFilesComponent } from "src/app/components/modals/add-files/add-files.component";
import { User } from "../../models/user";
import { ManagerService } from "../../services/manager.service";
import { TagModel } from "src/app/models/tags";
import { AngularEditorConfig } from "@kolkov/angular-editor";

const flatTypes = ["description", "title"];
@Component({
  selector: "app-add-product",
  templateUrl: "./add-product.component.html",
  styleUrls: ["./add-product.component.scss"],
})
export class AddProductComponent implements OnInit {
  @Output() showProducts: EventEmitter<boolean> = new EventEmitter();
  @Input("product")
  product: Product;
  public detailsForm: FormGroup;
  public linkForm: FormGroup;
  readonly today: NgbDateStruct = todayDate;
  public user: User;
  public countries: Country[];
  public languages: Language[];
  public files: File[] = [];
  public caracteristics: Caracteristic[] = [];
  public FileType = FILE_TYPE;
  public activeDocs: any;
  public activeEdit: number = -1;
  public urlInput: any;
  public libraryData = {};
  public isEnable: boolean = false;
  public links: Links[] = [];
  public tagForm: FormGroup;
  public tags: TagModel[] = [];
  public activeTagEdit: number = -1;
  editorConfig: AngularEditorConfig = EDITOR_CONFIG;
  planType=PlanType
  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private productService: ProductService,
    private _sanitizer: DomSanitizer,
    private managerService: ManagerService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.detailsForm = this.formBuilder.group({
      ean: [
        "",
        [Validators.required, Validators.pattern(VALIDATION_REGEX.EAN)],
      ],
      date: [null, [Validators.required]],
      enable: [false],
      title: ["", Validators.required],
      description: [""],
      country_id: [null, Validators.required],
      language_id: [null],
      manufacturer_reference: [null],
      asin: [""],
      manufacturer_name: ["", Validators.required],
      html_description:[""],
    });

    this.linkForm = this.formBuilder.group({
      sellerName: ["", Validators.required],
      imageUrl: [
        "",
        [Validators.required, Validators.pattern(VALIDATION_REGEX.WEBSITE)],
      ],
      linkUrl: [
        "",
        [Validators.required, Validators.pattern(VALIDATION_REGEX.WEBSITE)],
      ],
    });
    this.getCountries();
    this.getLanguages();
    this.setActiveDocs();

    this.loadUser();
    if(this.user.plan_type===PlanType.Discovery){
      this.detailsForm.get('enable').disable()
    }
    this.setLibraryData();
    this.managerService.switchLn.subscribe((ln: any) => {
      console.log("inside ln sub", ln);
      if (ln && ln.ln) this.translate.use(ln.ln);
    });
    this.initTagForm();
  }

  loadUser = async () => {
    this.user = getFromStorage("user");
    if (this.product) {
      if (this.user && this.user.role && this.user.role.name == "root") {
        this.product.manufacturer_name = this.user.manufacturer_name;
        this.isEnable = true;
      }
      this.setProduct();
      this.tags = this.product.tag.map((item) => ({
        tag_name: item.tag_name,
      }));
    } else {
      let valueToPatch = {};
      if (this.user && this.user.role && this.user.role.name == "root") {
        valueToPatch["manufacturer_name"] = this.user.manufacturer_name;
        this.isEnable = true;
      }
      this.detailsForm.patchValue(valueToPatch);
    }
  };

  setLibraryData = () => {
    let library = [
      "description",
      "caracteristics",
      "image",
      "driver",
      "video",
      "document",
      "title",
    ];
    for (let lib of library) {
      this.libraryData[lib] = {};
      this.libraryData[lib]["result"] = false;
      this.libraryData[lib]["data"] = [];
    }
  };

  setActiveDocs = () => {
    this.activeDocs = {
      document: {
        file: null,
        name: null,
        description: null,
      },
      driver: {
        file: null,
        name: null,
        description: null,
      },
      char: {
        caracteristique: null,
        description: null,
      },
      linkDetail: {
        link: null,
        url: null,
        name: null,
      },
    };
  };

  addProduct = async () => {
    let data = this.detailsForm.value;
    if (data.date) {
      data.date = new Date(
        data.date.year,
        data.date.month - 1,
        data.date.day
      ).valueOf();
    }
    data.source = SOURCE.WEB;
    if (this.files && this.files.length > 0)
      data.files = this.getProductFiles();
    else data.files = [];
    if (this.caracteristics && this.caracteristics.length > 0)
      data.caracteristics = this.caracteristics;
    else data.caracteristics = [];
    if (this.links && this.links.length > 0) data.links = this.links?.map((link:any)=>{ 
      const {creator_id ,...updatedLinkData} = link;
      return updatedLinkData;
    });
    else data.links = [];
    data.platform = PLATFORM;
    data.tags = this.tags;
    data = removeNullOrEmpty(data);
    if (this.product && this.product.id) data.id = this.product.id;
    this.spinner.show();
    try {
      let { success } = await this.productService.updateProduct(data);
      if (success) {
        let message =
          this.product && this.product.id
            ? SUCCESS_MESSAGES.PRODUCT_UPDATED
            : SUCCESS_MESSAGES.PRODUCT_ADDED;
        this.toastr.success(message);
        this.showProducts.emit(true);
      }
    } catch (err) {
      this.toastr.error(
        err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR
      );
    } finally {
      this.spinner.hide();
    }
  };

  formControl(formName) {
    return this[formName].controls;
  }

  getCountries = async () => {
    this.spinner.show();
    try {
      this.countries = await this.productService.getMetaData("country");
    } catch (err) {
      let message =
        err && err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR;
      this.toastr.error(message);
    } finally {
      this.spinner.hide();
    }
  };

  getLanguages = async () => {
    this.spinner.show();
    try {
      this.languages = await this.productService.getMetaData("language");
    } catch (err) {
      let message =
        err && err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR;
      this.toastr.error(message);
    } finally {
      this.spinner.hide();
    }
  };

  doCancel = () => {
    this.showProducts.emit(false);
  };

  selectFile = async (event, type) => {
    let {
      target: { files },
    } = event;
    let [file] = files;
    let [fileType] = file.type.split("/");

    if ((type === "image" || type === "video") && fileType !== type) {
      this.showConfirmationDialog("Invalid File Format.");
      event.srcElement.value = null;
      return;
    }

    if (file && file.size > MAX_FILE_SIZE) {
      this.showConfirmationDialog(
        "File too Big! Please select a file less than 100mb"
      );
      event.srcElement.value = null;
      this.activeDocs[type].description = "";
      return;
    }

    if (file) {
      switch (FILE_TYPE[type]) {
        case FILE_TYPE.image:
          return this.uploadFileToS3(file, type);
        case FILE_TYPE.video:
          return this.uploadFileToS3(file, type);
        case FILE_TYPE.document:
          return this.addDoc(file, type);
        case FILE_TYPE.driver:
          return this.addDoc(file, type);
      }
    }
  };

  addDoc = (file, type) => {
    this.activeDocs[type].file = file;
    this.activeDocs[type].name = file.name;
  };

  uploadDoc = (type) => {
    this.uploadFileToS3(this.activeDocs[type].file, type);
  };

  uploadFileToS3 = (file, type) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async (event) => {
      let fl = new File();
      fl.buffer_url = reader.result;
      fl.type_id = FILE_TYPE[type];
      if (
        FILE_TYPE[type] === FILE_TYPE.driver ||
        FILE_TYPE[type] === FILE_TYPE.document
      ) {
        fl.description = this.activeDocs[type].description;
        this.resetActiveDoc(type);
      }
      fl.url = await this.getFileUrl(file);
      if (fl.url) this.addFile(fl);
    };
  };

  addFile = (file: File) => {
    this.files.push(file);
  };

  resetActiveDoc = (type) => {
    for (let key of Object.keys(this.activeDocs[type])) {
      this.activeDocs[type][key] = null;
    }
  };

  removeFile = (file) => {
    let index;
    this.files.forEach((fl, i) => {
      if (fl.url === file.url) index = i;
    });
    this.files.splice(index, 1);
  };

  getFileUrl = async (file) => {
    let params = {
      file_name: file.name,
      content_type: file.type,
    };
    this.spinner.show();
    try {
      // get signed url from backend
      let { key, url } = (await this.productService.updateFile(params)) || {};
      // upload file to signed url
      await this.productService.uploadFile(url, file);
      this.spinner.hide();
      // return s3 url
      return getS3Url(key);
    } catch (err) {
      this.spinner.hide();
      let message =
        err && err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR;
      this.toastr.error(message);
    }

    return null;
  };

  getProductFiles = () => {
    let files = [];
    for (let type of Object.keys(FILE_TYPE)) {
      let fls = this.files.filter((file) => file.type_id === FILE_TYPE[type]);
      fls.map((fl, index) => {
        fl.order = index + 1;
        delete fl.buffer_url;
        delete fl.external_url;
      });
      files = [...fls, ...files];
    }
    return files;
  };

  addLinks = () => {
    let linkDetail = new Links();

    if (
      this.activeDocs &&
      this.activeDocs.linkDetail &&
      this.activeDocs.linkDetail.link
    )
      linkDetail.link = this.activeDocs.linkDetail.link;
    linkDetail.name = this.activeDocs.linkDetail.name;
    linkDetail.url = this.activeDocs.linkDetail.url;

    if (this.activeEdit >= 0) {
      this.links[this.activeEdit] = linkDetail;
      this.activeEdit = -1;
    } else this.links.push(linkDetail);
    this.resetActiveDoc("linkDetail");
    this.linkForm.reset();
  };

  removeLink = (index) => {
    this.links.splice(index, 1);
  };

  editLink = (index) => {
    this.activeEdit = index;
    this.activeDocs.linkDetail = { ...[...this.links][index] };
  };

  addChars = () => {
    let char = new Caracteristic();
    char.caracteristique = this.activeDocs.char.caracteristique;
    char.description = this.activeDocs.char.description;
    if (this.activeEdit >= 0) {
      this.caracteristics[this.activeEdit] = char;
      this.activeEdit = -1;
    } else {
      this.caracteristics.push(char);
    }
    this.resetActiveDoc("char");
  };

  removeChar = (index) => {
    this.caracteristics.splice(index, 1);
  };

  editChar = (index) => {
    this.activeEdit = index;
    this.activeDocs.char = { ...[...this.caracteristics][index] };
  };

  setProduct = () => {
    const valuesToPatch = [
      "ean",
      "date",
      "enable",
      "title",
      "description",
      "country_id",
      "language_id",
      "asin",
      "manufacturer_reference",
      "manufacturer",
      "html_description",
    ];
    for (let val of valuesToPatch) {
      switch (val) {
        case "date":
          this.patchFormValue(val, convertMSToNgbDate(this.product.date));
          break;
        case "country_id":
          this.patchFormValue(val, this.product.country.id);
          break;
        case "language_id":
          if (this.product.language_id) {
            this.patchFormValue(val, this.product.language_id);
          }
          break;
        case "manufacturer":
          this.patchFormValue(
            "manufacturer_name",
            this.product.manufacturer_name
          );
          break;
        default:
          this.patchFormValue(val);
          break;
      }
    }

    const productFiles = ["links", "caracteristics", "files"];
    for (let file of productFiles) {
      if (this.product[file] && this.product[file].length > 0) {
        this[file] = this.product[file];
        for (let fl of this[file]) {
          if (fl.product_id) delete fl.product_id;
          if (fl.file_type) delete fl.file_type;
          if (
            file === "files" &&
            fl.type_id &&
            fl.type_id === FILE_TYPE.video
          ) {
            let sanUrl = this.getSanatizedUrl(fl.url);
            if (sanUrl) fl.external_url = sanUrl;
          }
        }
      }
    }
  };

  patchFormValue = (key, value = null) => {
    let patch = {};
    patch[key] = value ? value : this.product[key];
    this.detailsForm.patchValue(patch);
  };

  getLibrary = async (type) => {
    if (!this.detailsForm.value.ean) {
      this.showConfirmationDialog("Please enter EAN first.", false);
      return;
    }
    let modal;
    let title;
    switch (type) {
      case "description":
        modal = AddFilesComponent;
        title = "Descriptions";
        break;
      case "caracteristics":
        modal = AddCharacteristicsComponent;
        title = "Caracterstics";
        break;
      case "image":
        modal = AddMediaComponent;
        title = "Add Images";
        break;
      case "video":
        modal = AddMediaComponent;
        title = "Add Videos";
        break;
      case "document":
        modal = AddFilesComponent;
        title = "Add Files";
        break;
      case "driver":
        modal = AddFilesComponent;
        title = "Add Files";
        break;
      case "title":
        modal = AddFilesComponent;
        title = "Add Title";
    }
    let modalInput = await this.getLibraryData(type);
    const modalRef = this.modalService.open(modal, { size: "lg" });
    modalRef.componentInstance.data = modalInput;
    modalRef.componentInstance.title = title;
    modalRef.result.then((result: any) => {
      let { success, data } = result;
      let dataType = this.getDataType(type);
      if (success) {
        if (flatTypes.includes(dataType)) {
          let str = "";
          if (data.length > 0) {
            data.forEach((element) => {
              if (element.description) str = str + " " + element.description;
            });
          }
          let patchObj = {};
          patchObj[dataType] = str;
          this.detailsForm.patchValue(patchObj);
        } else {
          this[dataType] = [...this[dataType], ...data];
        }
      }
    });
  };

  getLibraryData = async (type) => {
    if (this.libraryData[type].result) return this.libraryData[type].data;
    let queryParams = {
      ean: this.detailsForm.value.ean,
      limit: LIBRARY_COUNT,
      param: type,
    };
    this.spinner.show();
    let products = await this.productService.getPublicProducts(queryParams);
    this.spinner.hide();
    let data = [];
    let dataType = this.getDataType(type);
    for (let product of products) {
      if (flatTypes.includes(dataType)) {
        if (product[dataType])
          data = [...data, ...[{ description: product[dataType] }]];
      } else {
        if (product[dataType]) data = [...data, ...product[dataType]];
      }
    }

    this.libraryData[type].result = true;
    this.libraryData[type].data = data;
    return data;
  };

  showConfirmationDialog = (message, showCancel = false) => {
    const modalRef = this.modalService.open(ConfirmationModalComponent);
    modalRef.componentInstance.message = message;
    if (showCancel) modalRef.componentInstance.showCancel = showCancel;
  };

  getDataType = (type) => {
    if (Object.keys(this.FileType).includes(type)) return "files";
    return type;
  };

  addExternalUrl(type) {
    if (
      !this.urlInput.match(
        /(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/
      )
    ) {
      const modalRef = this.modalService.open(ConfirmationModalComponent);
      modalRef.componentInstance.message =
        "Invalid URL, Please enter a valid youtube/vimeo url.";
    } else {
      let fl = new File();
      fl.url = this.urlInput;
      fl.external_url = this.getSanatizedUrl(this.urlInput);
      fl.type_id = FILE_TYPE[type];
      this.addFile(fl);
    }
    this.urlInput = null;
  }

  getSanatizedUrl(url) {
    if (url.search("youtu") > 0) return this.getYouTubeUrl(url);
    if (url.search("vimeo") > 0) return this.getVimeoUrl(url);
    return null;
  }

  getYouTubeUrl(ytUrl: string) {
    var regExp =
      /^.*((youtube\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = ytUrl.match(regExp);
    return this._sanitizer.bypassSecurityTrustResourceUrl(
      "https://www.youtube.com/embed/".concat(match[7])
    );
  }

  getVimeoUrl(vUrl: string) {
    return this._sanitizer.bypassSecurityTrustResourceUrl(
      "https://player.vimeo.com/video/".concat(
        /(vimeo(pro)?\.com)\/(?:[^\d]+)?(\d+)\??(.*)?$/.exec(vUrl)[3]
      )
    );
  }
  initTagForm() {
    this.tagForm = this.formBuilder.group({
      tagName: ["", Validators.required],
    });
  }
  editTag(index: number) {
    this.activeTagEdit = index;
    this.tagForm?.get("tagName")?.patchValue(this.tags[index].tag_name);
  }
  removeTag(index: number) {
    this.tags.splice(index, 1);
  }
  addTag() {
    if (this.activeTagEdit >= 0) {
      this.tags[this.activeTagEdit] = {
        ...this.tags[this.activeTagEdit],
        tag_name: this.tagForm?.get("tagName")?.value ?? "",
      };
      this.tagForm?.get("tagName")?.patchValue("");
      this.activeTagEdit = -1;
    } else {
      this.tags.push({ tag_name: this.tagForm?.get("tagName")?.value ?? "" });
      this.tagForm?.get("tagName")?.patchValue("");
    }
  }
}
