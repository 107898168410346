<div class="modal-header">
    <h4 class="mb-0">QRCode</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="d-flex justify-content-center align-items-center">
      <qrcode [qrdata]="qrLink" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
  </div>  
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
  </div>