// core modules
import { Component, OnInit, QueryList, ViewChildren } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { SortEvent, TableSortableHeader } from "../../directives/tablesortableheader.directives";
import {TranslateService} from '@ngx-translate/core';

// project modules
import { ProductService } from '../../services/product.service';
import { PAGE_COUNT, SUCCESS_MESSAGES, ERROR_MESSAGES, PlanType } from 'src/app/common/constants';
import { Product } from '../../models/product';
import { ManagerService } from '../../services/manager.service';
import { Language } from "src/app/models/language";
import { getFromStorage } from "src/app/common/utils";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { QrCodePopupComponent } from "./qr-code-popup/qr-code-popup.component";
import { v4 as uuidv4 } from 'uuid';

const compare = (v1: any, v2: any) => {
  return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
};

@Component({
  selector: "app-view-products",
  templateUrl: "./view-products.component.html",
  styleUrls: ["./view-products.component.scss"],
})
export class ViewProductsComponent implements OnInit {
  public products: Product[];
  public addProduct: boolean = false;
  public product: Product;
  searchText: string = "";
  public page: number = 0;
  public pageCount: number = PAGE_COUNT;
  public totalPage: number = 0;
  public languages: Language[];
  public languageMap={}
  public user!:any;
  planType=PlanType
  @ViewChildren(TableSortableHeader) headers: QueryList<TableSortableHeader>;
  constructor(
    private spinner: NgxSpinnerService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private productService: ProductService,
    private translate: TranslateService,
    private managerService: ManagerService,
    private readonly modalService: NgbModal,
  ) {}

  ngOnInit(): void {
    // Check if edit product or list product
    this.getLanguages();
    if (this.activatedRoute.snapshot.paramMap.get("id")) {
      this.getProduct(this.activatedRoute.snapshot.paramMap.get("id"));
    } else {
      this.loadProducts();
    }
    this.managerService.switchLn.subscribe((ln: any) => {
      console.log("inside ln sub", ln);
      if (ln && ln.ln) this.translate.use(ln.ln);
    });
    this.user=getFromStorage("user");
  }

  getProduct = async (id) => {
    this.spinner.show();
    try {
      this.product = await this.productService.getProductById(id);
      this.spinner.hide();
      this.addProduct = true;
    } catch (err) {
      this.spinner.hide();
      this.toastr.error(
        err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR
      );
    }
  };

  loadProducts = async (page = null) => {
    console.log(this.searchText);
    if (page) {
      this.page = page;
    } else {
      this.page++;
    }
    this.spinner.show();
    try {
      let { results, totalCount } = await this.productService.getProducts(
        this.page,
        PAGE_COUNT,
        this.searchText.trim()
      );
      this.totalPage = totalCount;
      this.products = results;
      this.spinner.hide();
      this.addProduct = false;
    } catch (err) {
      this.spinner.hide();
      this.toastr.error(
        err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR
      );
    }
  };

  generateQRCode(){
    const uuid = uuidv4();
    const lang=this.languages.find((el)=>el.id===this.product.language_id)
    const link = `https://dpp-portal.eu/${this.product?.country?.code}/${lang.code}/${this.product?.ean}/${uuid}`;
    const modalRef = this.modalService.open(QrCodePopupComponent);
    modalRef.componentInstance.qrLink = link;
  }

  public onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = "";
      }
    });

    // sorting countries
    if (direction === "" || column === "") {
    } else {
      this.products = [...this.products].sort((a, b) => {
        const res = compare(a[column], b[column]);
        return direction === "asc" ? res : -res;
      });
    }
  }

  showList = (event) => {
    this.addProduct = false;
    this.searchText = "";
    this.page = 0;
    this.loadProducts();
    this.router.navigate([`manager/products`]);
  };

  goToProduct = (event, id) => {
    event.stopPropagation();
    this.router.navigate([`manager/products/${id}`]);
  };

  changeEnable = async (index, enable) => {
    console.log(index);
    let data = {
      id: this.products[index].id,
      enable: !enable,
    };
    this.spinner.show();
    try {
      let { success } = await this.productService.updateProduct(data);
      if (success) {
        let message =
          this.product && this.product.id
            ? SUCCESS_MESSAGES.PRODUCT_UPDATED
            : SUCCESS_MESSAGES.PRODUCT_UPDATED;
        this.toastr.success(message);
        this.loadProducts(this.page);
      }
    } catch (err) {
      this.toastr.error(err.message);
    } finally {
      this.spinner.hide();
    }
  };

  setPage = (page) => {
    this.loadProducts(page);
  };
  getLanguages = async () => {
    this.spinner.show();
    try {
      this.languages = await this.productService.getMetaData("language");
      
    this.languages?.forEach((lang) => {
      this.languageMap[lang.id] = lang.name;});
    } catch (err) {
      let message =
        err && err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR;
      this.toastr.error(message);
    } finally {
      this.spinner.hide();
    }
  };
}
