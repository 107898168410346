<div class="main-produ  mt-4 mb-5 d-inline-block w-100">

  <h2 class="text-dark font-weight-bold">{{'products' | translate }}<span class="search-bx">

    <input type="text" name="search" id="searchText" [(ngModel)]="searchText" placeholder="{{'quicksearch' |  translate }}"
    (keyup.enter)="loadProducts(1)" *ngIf="!addProduct" class="border border-primary">

    <span><a *ngIf="!addProduct" (click)="addProduct = true" class="rounded font-weight-bold font-size-18 ml-1 ml-md-5 text-white">{{'add' | translate}} {{'a' | translate}} {{'product' | translate}}</a></span> 
     
    <span><a *ngIf="product" (click)="generateQRCode()" class="rounded font-weight-bold font-size-18 ml-1 ml-md-5 text-white">{{'Generate QRCode' | translate}}</a></span> 
    <span><a *ngIf="addProduct" (click)="addProduct = !addProduct" class="rounded font-weight-bold font-size-18 ml-1 ml-md-5 text-white">{{'back' | translate}}</a></span>

  </span></h2>

</div>
<br>
<div *ngIf="!addProduct" class="wrapper">
  <div class="table-responsive" *ngIf="products && products?.length > 0">
    <div class="force-overflow"></div>
    <table class="table table-bordered table-hover mb-0 table-fixed">
      <thead class="text-primary">
        <tr>
          <th scope="col" sortable="ean" (sort)="onSort($event)">EAN</th>
          <th scope="col" sortable="title" (sort)="onSort($event)">{{'title' | translate }}</th>
          <th scope="col">{{'description' | translate }} </th>
          <th class="col4" scope="col"> {{'country' | translate }}</th>
          <th class="col4" scope="col"> {{'language' | translate }}</th>
          <th scope="col"> {{'enabled' | translate }}</th>
        </tr>
      </thead>

      <tbody class="text-dark">
        <tr *ngFor="let product of products; let i = index">
          <td class="cursor-pointer" (click)="goToProduct($event, product.id)">{{product?.ean}}</td>
          <td class="cursor-pointer" (click)="goToProduct($event, product.id)">{{product?.title}}</td>
          <td class="cursor-pointer" (click)="goToProduct($event, product.id)">{{product?.description}}</td>
          <td class="cursor-pointer" (click)="goToProduct($event, product.id)">{{product?.country?.code}}</td>
          <td class="cursor-pointer" (click)="goToProduct($event, product.id)">{{ product?.language_id ? languageMap[product.language_id]:''}}</td>
          <td>
            <div class="custom-control custom-switch ">
              <input type="checkbox" class="custom-control-input" [id]="i" [checked]="product?.enable" (change)="changeEnable(i, product?.enable)" [disabled]="user?.plan_type===planType.Discovery"/>
              <label class="custom-control-label" [for]="i"></label>
            </div>
          </td>
        </tr>

    </table>
  </div>
  <app-pagination [totalItems]="totalPage" [pageSize]="pageCount" (pageClicked)="setPage($event)"
      *ngIf="totalPage > pageCount">
    </app-pagination>

  <div *ngIf="!products || products?.length === 0"> {{'no' | translate }} {{'products' | translate }}</div>
</div>

<div *ngIf="addProduct">
  <app-add-product (showProducts)="showList($event)" [product]="product"></app-add-product>
</div>